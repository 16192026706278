<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      class="b"
      width="24"
      height="24"
    />
    <path
      class="a"
      d="M15.974,31.448a.324.324,0,0,0,.011-.212l-1.318-4.283V23A1.669,1.669,0,0,0,13,21.334H3A1.669,1.669,0,0,0,1.333,23v3.952L.015,31.236a.325.325,0,0,0,.011.212A.988.988,0,0,0,0,31.667v4A.333.333,0,0,0,.333,36H1.667A.333.333,0,0,0,2,35.667v-1H14v1a.333.333,0,0,0,.333.333h1.333A.333.333,0,0,0,16,35.667v-4a.99.99,0,0,0-.026-.219ZM2,23a1,1,0,0,1,1-1H13a1,1,0,0,1,1,1v3.667h-.906l-.311-1.243a1,1,0,0,0-.971-.757H9.667a1,1,0,0,0-1,1v1H7.333v-1a1,1,0,0,0-1-1H4.187a1,1,0,0,0-.971.757L2.9,26.666H2Zm10.409,4.2a.333.333,0,0,1-.263.128H9.667A.334.334,0,0,1,9.333,27V25.667a.334.334,0,0,1,.333-.333h2.147a.332.332,0,0,1,.323.252l.333,1.333a.331.331,0,0,1-.06.287ZM6.667,25.667V27h0a.333.333,0,0,1-.333.332H3.855a.333.333,0,0,1-.324-.415l.333-1.333a.333.333,0,0,1,.324-.252H6.334a.334.334,0,0,1,.333.335ZM1.913,27.334h1A.972.972,0,0,0,3.854,28H6.333a1,1,0,0,0,.939-.667H8.728A1,1,0,0,0,9.667,28h2.479a1,1,0,0,0,.789-.384.982.982,0,0,0,.149-.283h1l1.029,3.345A.962.962,0,0,0,15,30.667H1a.938.938,0,0,0-.116.012Zm-.579,8H.667v-.667h.667Zm14,0h-.667v-.667h.667Zm0-1.333H.667V31.667A.334.334,0,0,1,1,31.334H15a.334.334,0,0,1,.333.333Z"
      transform="translate(4 -16)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Overnight',
};
</script>

<style lang="scss" scoped>
.a {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.3px;
}
.b {
  fill:none;
}
</style>
